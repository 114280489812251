<template>
    <div class="reading-container" @click="treePanelVisible = false">
        <!-- 头部选择框 -->
        <CSTabBar
                :tabbar="tabBar"
                :checked-tab="checkedTaBar"
                @changeTabBar="changeTabBar"
        />
        <!-- 条件查询框 -->
        <div class="filter-panel">
            <div
                    style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
            >
                <input
                        class="input"
                        type="text"
                        :placeholder="inputName"
                        style="
            color: #000;
            width: 400px;
            height: 30px;
            padding: 5px 28px 5px 10px;
            border-radius: 3px;
            border: 1px solid #999999;
            margin-right: 30px;
          "
                        v-model="inputCondition"
                />
            </div>
            <CSSelect v-if="checkedTaBar == 1 || checkedTaBar == 2">
                <select v-model="checkedGroupId">
                    <option value="">表组不限</option>
                    <Option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </select>
            </CSSelect>
            <CSSelect v-if="checkedTaBar == 1 || checkedTaBar == 2">
                <select v-model="balanced">
                    <Option v-for="item in groupListMony" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </select>
            </CSSelect>
            <button type="button" class="btn btn-primary btn-sm" @click="query()">
                查询
            </button>
        </div>
        <!-- 列表显示框 -->
        <div class="result-panel" v-if="checkedTaBar == 1">
            <div style="padding-top: 20px;margin-left: 30px;display: inline-block">
                水表总数：{{total}}
            </div>
            <div style="padding-top: 20px;margin-left: 30px;display: inline-block">
                剩余预存金额总计：{{balanceSum}}
            </div>
            <hr>
            <CSTable :thead-top="60">
                <template v-slot:thead>
                    <tr>
                        <th>最新抄表时间</th>
                        <th>水表编号</th>
                        <th>最新读数</th>
                        <th>倍率规则</th>
                        <th>倍率</th>
                        <th>水价（元/吨）</th>
                        <th>当前剩余预存金额（元)</th>
                        <th>表箱号</th>
                        <th>位置</th>
                        <th>表组</th>
                        <th>关联房间</th>
                        <th>当前业主/租客</th>
                        <th>备注</th>
                        <!--                        <th style="min-width: 380px">操作</th>-->
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in waterReadingList" :key="item.id">
                        <td style="min-width: 125px">
                            <span>{{ item.updateTime || '-' }}</span>
                        </td>
                        <td>
                            <span>{{ item.imei }}</span>
                        </td>
                        <td>
                            <span>{{ item.ton }}</span>
                        </td>
                        <td>
                            <span>{{item.rationRule?'用量=读数/倍率':'用量=读数*倍率'}}</span>
                        </td>
                        <td>
                            <span>{{ item.rate || '-' }}</span>
                        </td>


                        <td>
                            <span>{{ $vc.summationBig(item.basisPrice,item.additionPrice) || '-' }}</span>
                        </td>


                        <td style="min-width: 160px">
                            <span>{{ item.balance || '0' }}</span>
                        </td>
                        <td>
                            <span>{{item.meterBoxNo || '-' }}</span>
                        </td>
                        <td>
                            <span>{{item.locationName || '-' }}</span>
                        </td>
                        <td>
                            <span>{{item.groupNames ? item.groupNames.join("、"):"-" }}</span>
                        </td>
                        <td>
              <span>{{
                item.correlationRoom ? item.correlationRoom : "-"
              }}</span>
                        </td>
                        <td>
              <span
                      class="btn-active"
                      v-if="item.companyName"
                      @click="getInformation(item)"
              >{{ item.companyName }}</span
              >
                            <span v-else> - </span>
                        </td>
                        <td v-if="item.commit">
                            <span class="allow-click" @click="lookComment(item.commit)">
                                查看
                            </span>
                        </td>
                        <td v-else>
                            -
                        </td>
                        <!--                        <td>-->
                        <!--                            <div class="btn-group">-->
                        <!--                                <button-->
                        <!--                                        type="button"-->
                        <!--                                        class="btn btn-primary dropdown-toggle"-->
                        <!--                                        data-toggle="dropdown"-->
                        <!--                                        aria-haspopup="true"-->
                        <!--                                        aria-expanded="false"-->
                        <!--                                >-->
                        <!--                                    操作-->
                        <!--                                </button>-->
                        <!--                                <ul class="dropdown-menu" style="width: 112px">-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="enableResidueBalance(item,1)">重算剩余预存</a>-->
                        <!--                                    </li>-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="enableEditMeterBoxNo(item,1)">表箱号</a>-->
                        <!--                                    </li>-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="enableEditMeterLocation(item,1)">关联位置</a>-->
                        <!--                                    </li>-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="openTableGroup(item,$event,1)">关联表组</a>-->
                        <!--                                    </li>-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="enableEditMeterCommit(item,1)">备注</a>-->
                        <!--                                    </li>-->
                        <!--                                </ul>-->
                        <!--                            </div>-->
                        <!--                        </td>-->
                    </tr>
                </template>
            </CSTable>
            <Pagination name="pagination" componentName="Pagination"></Pagination>
        </div>
        <div class="result-panel" v-if="checkedTaBar == 2">
            <div style="padding-top: 20px;margin-left: 30px;display: inline-block">
                电表总数：{{total}}个
            </div>
            <div style="padding-top: 20px;margin-left: 30px;display: inline-block">
                剩余预存金额总计：{{remnantSum}}元
            </div>
            <hr>
            <CSTable :thead-top="60">
                <template v-slot:thead>
                    <tr>
                        <th>最新抄表时间</th>
                        <th>电表编号</th>
                        <th>最新读数</th>
                        <th>倍率规则</th>
                        <th>倍率</th>
                        <th>电价（元/度）</th>
                        <th>当前剩余预存金额（元)</th>
                        <th>表箱号</th>
                        <th>位置</th>
                        <th>表组</th>
                        <th>关联房间</th>
                        <th>当前业主/租客</th>
                        <th>备注</th>
                        <!--                        <th style="min-width: 380px">操作</th>-->
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in electricReadingList" :key="item.id">
                        <td style="min-width: 125px">
                            <span>{{ item.updateTime || '-' }}</span>
                        </td>
                        <td>
                            <span>{{ item.imei }}</span>
                        </td>
                        <td>
                            <span>{{ item.ton }}</span>
                        </td>
                        <td>
                            <span>{{item.rationRule?'用量=读数/倍率':'用量=读数*倍率'}}</span>
                        </td>
                        <td>
                            <span>{{ item.coefficient || '-' }}</span>
                        </td>


                        <td>
                            <span>{{ $vc.summationBig(item.basisPrice,item.additionPrice) || '-' }}</span>
                        </td>


                        <td style="min-width: 160px">
                            <span>{{ item.balance || '0' }}</span>
                        </td>
                        <td>
                            <span>{{item.meterBoxNo || '-' }}</span>
                        </td>
                        <td>
                            <span>{{item.locationName || '-' }}</span>
                        </td>
                        <td>
                            <span>{{item.groupNames ? item.groupNames.join("、"):"-" }}</span>
                        </td>
                        <td>
              <span>{{
                item.correlationRoom ? item.correlationRoom : "-"
              }}</span>
                        </td>
                        <td>
              <span
                      class="btn-active"
                      v-if="item.companyName"
                      @click="getInformation(item)"
              >{{ item.companyName }}</span
              >
                            <span v-else> - </span>
                        </td>
                        <td v-if="item.commit">
                            <span class="allow-click" @click="lookComment(item.commit)">
                                查看
                            </span>
                        </td>
                        <td v-else>
                            -
                        </td>
                        <!--                        <td>-->
                        <!--                            <div class="btn-group">-->
                        <!--                                <button-->
                        <!--                                        type="button"-->
                        <!--                                        class="btn btn-primary dropdown-toggle"-->
                        <!--                                        data-toggle="dropdown"-->
                        <!--                                        aria-haspopup="true"-->
                        <!--                                        aria-expanded="false"-->
                        <!--                                >-->
                        <!--                                    操作-->
                        <!--                                </button>-->
                        <!--                                <ul class="dropdown-menu" style="width: 112px">-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="enableResidueBalance(item,1)">重算剩余预存</a>-->
                        <!--                                    </li>-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="enableEditMeterBoxNo(item,1)">表箱号</a>-->
                        <!--                                    </li>-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="enableEditMeterLocation(item,1)">关联位置</a>-->
                        <!--                                    </li>-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="openTableGroup(item,$event,1)">关联表组</a>-->
                        <!--                                    </li>-->
                        <!--                                    <li>-->
                        <!--                                        <a @click="enableEditMeterCommit(item,1)">备注</a>-->
                        <!--                                    </li>-->
                        <!--                                </ul>-->
                        <!--                            </div>-->
                        <!--                        </td>-->
                    </tr>
                </template>
            </CSTable>
            <Pagination name="pagination" componentName="Pagination"></Pagination>
        </div>
        <div class="result-panel" v-if="checkedTaBar == 3">
            <CSTable :thead-top="60">
                <template v-slot:header>
                    <div class="table-header-panel" style="text-align: right">
                        <button
                                class="btn btn-primary sticky-right"
                                @click="enableAddWaterGroup"
                        >
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            表组
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>表组名称</th>
                        <th>水表</th>
                        <th>电表</th>
                        <th>备注</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in MeterGroupList" :key="item.id">
                        <td>
                            {{ item.createTime}}
                        </td>
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            <span class="allow-click" @click="seeWaterBtn(item.id,1)">
                                {{ item.waterCount || `0` }}
                            </span>
                        </td>
                        <td>
                            <span class="allow-click" @click="seeElectricityBtn(item.id,2)">
                                 {{ item.electricityCount || `0`}}
                            </span>
                        </td>
                        <td v-if="item.commit">
                            <span class="allow-click" @click="lookComment(item.commit)">
                                查看
                            </span>
                        </td>
                        <td v-else>
                            -
                        </td>
                        <td>
                            <div class="btn-group">
                                <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul class="dropdown-menu" style="width: 76px">
                                    <li>
                                        <a @click="enableEditWaterGroup(item)">修改</a>
                                    </li>
                                    <li>
                                        <a @click="deleteMeterGroup(item.id)">删除</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination name="pagination" componentName="Pagination"></Pagination>
        </div>

        <!-- 添加表组 -->
        <CSDialog
                :dialogTitle="`添加表组`"
                :dialog-visible="addWaterGroupFlag"
                :dialog-width="600"
                @onClose="addWaterGroupFlag = false"
                @onConfirm="addWaterGroup"

        >
            <template v-slot:dialog-content>
                <div class="dialog-form" style="padding:30px 0">
                    <div class="dialog-form-item" style="margin-bottom: 0">
                        <div class="dialog-form-item-label" style="width: 130px">表组名称</div>
                        <div>
                            <input
                                    class="input"
                                    type="text"
                                    :maxlength="20"
                                    style="
                  width: 368px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限10个字"
                                    v-model="addWaterGroupInfo.name"
                            />
                        </div>
                    </div>
                    <div class="preview-tenant">
                        <div class="field">
                            <span class="field-label" style="width: 100px;margin-right: 40px">备注</span>
                            <textarea
                                    style="
                                resize:none;
                                padding:10px;
                                width:370px;
                                height:200px;
                                overflow-y:auto;
                                background-color:#F0F0F0;
                                border-radius:4px;
                                border:0px;
                            "
                                    maxlength="50"
                                    placeholder="限50个字"
                                    v-model="addWaterGroupInfo.commit"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!-- 修改表组 -->
        <CSDialog
                :dialogTitle="`修改表组`"
                :dialog-visible="editWaterGroupFlag"
                :dialog-width="590"
                @onClose="editWaterGroupFlag = false"
                @onConfirm="editWaterGroup"
        >
            <template v-slot:dialog-content>
                <div class="dialog-form" style="padding:30px 0">
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">表组名称</div>
                        <div>
                            <input
                                    class="input"
                                    type="text"
                                    :maxlength="20"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限10个字"
                                    v-model="editWaterGroupInfo.name"
                            />
                        </div>
                    </div>
                    <div class="preview-tenant">
                        <div class="field">
                            <span class="field-label" style="width: 120px">备注</span>
                            <textarea
                                    style="
                                resize:none;
                                padding:10px;
                                width:370px;
                                height:200px;
                                overflow-y:auto;
                                background-color:#F0F0F0;
                                border-radius:4px;
                                border:0px;
                            "
                                    maxlength="50"
                                    placeholder="限50个字"
                                    v-model="editWaterGroupInfo.commit"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!--修改备注-->
        <CSDialog
                :dialogTitle="`备注`"
                :dialog-visible="editCommitFlag"
                :dialog-width="530"
                @onClose="editCommitFlag = false"
                @onConfirm="editMeterCommit"
        >
            <template v-slot:dialog-content>
                <div class="preview-tenant">
                    <div class="field">
                        <span class="field-label">备注</span>
                        <textarea
                                style="
                                resize:none;
                                padding:10px;
                                width:370px;
                                height:200px;
                                overflow-y:auto;
                                background-color:#F0F0F0;
                                border-radius:4px;
                                border:0px;
                            "
                                maxlength="50"
                                placeholder="限50个字"
                                v-model="editCommitInfo.commit"
                        ></textarea>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!--修改表箱号-->
        <CSDialog
                :dialogTitle="`表箱号`"
                :dialog-visible="editMeterBoxNoFlag"
                :dialog-width="575"
                @onClose="editMeterBoxNoFlag = false"
                @onConfirm="editMeterBoxNo"
        >
            <template v-slot:dialog-content>
                <div class="dialog-form" style="padding:30px 0">
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label" style="width: auto;padding-left: 30px">表箱号</div>
                        <div>
                            <input v-model="editMeterBoxNoInfo.meterBoxNo" type="text" style="width: 400px"
                                   class="input"
                                   placeholder="限20个字">
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!--修改位置-->
        <CSDialog
                class="tenant-detail-dialog"
                dialog-title="关联位置"
                dialog-width="560px"
                :dialog-visible="editMeterLocationFlag"
                @onClose="editMeterLocationFlag = false"
                @onConfirm="editMeterLocation"
        >
            <template
                    v-slot:dialog-content
            >
                <div class="dialog-form">
                    <div class="dialog-form-item" style="display: flex;align-items: center;">
                        <div class="dialog-form-item-label" style="vertical-align: top;width: auto;">位置</div>
                        <div style="width:400px">
                            <ChooseLocation @changeLocation="changeLocation"
                                            :defaultLocationName="editMeterLocationInfo.locationName"/>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!--修改水表剩余预存-->
        <CSDialog
                dialogTitle="重算剩余预存金额"
                :dialogWidth="520"
                :dialogVisible="editResidueBalanceFlag"
                dialogConfirmBtnText="确认更新"
                @onClose="clearBalance"
                @onConfirm="editWaterBalance"
        >
            <div
                    slot="dialog-content" class="dialog-content"
                    style="padding: 30px 30px 20px; font-size: 24px;"
            >
                <span style="margin-right: 30px">选择日期</span>
                <CSSelect i-width="40px" class="wData">
                    <el-date-picker
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择"
                            prefix-icon="el-icon-time"
                            :editable="true"
                            v-model="queryBalanceParams.startTime"
                    ></el-date-picker>
                </CSSelect>
                <button
                        style="margin-left: 20px"
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="queryWaterBalance"
                >
                    计算
                </button>
            </div>
            <div
                    slot="dialog-content" class="dialog-content"
                    style="padding: 10px 30px 10px; font-size: 24px;"
            >
                <span v-if="balance == null ">剩余预存金额(元)&nbsp;&nbsp;暂未计算</span>
                <span v-else>剩余预存金额(元)&nbsp;&nbsp;{{balance}}</span>
            </div>
            <div
                    slot="dialog-content" class="dialog-content"
                    style="padding: 10px 30px 20px; font-size: 24px;"
            >
                <svg
                        class="icon"
                        aria-hidden="true"
                        style="font-size: 20px; margin-right: 10px"
                >
                    <use xlink:href="#icon-menua-zu92"></use>
                </svg>

                <span style="font-size: 20px;color: #999999"
                >只计算所选日期当天及之后产生的金额总计。</span
                >
            </div>
        </CSDialog>

        <!--关联表组-->
        <CSDialog
                class="tenant-detail-dialog"
                dialog-title="关联表组"
                dialog-width="548px"
                :dialog-visible="tableGroupDiog"
                @onClose="tableGroupDiog = false"
                @onConfirm="editTableGroup"

        >
            <template
                    v-slot:dialog-content
            >
                <div class="dialog-form bioz" @click="treePanelVisibleD">
                    <div class="dialog-form-item" style="display: flex;align-items: center">
                        <div class="dialog-form-item-label" style="vertical-align: top;width: auto;">表组</div>
                        <div style="width:400px;position: relative">
                            <CSSelect style="height: 40px;width:400px" i-width="40px">
                                <!--                                <input v-model="groupNumber" type="text" style="width: 100%;border: none" -->
                                <!--                                       placeholder="" @click="treePanelVisibleBtn">-->
                                <div @click="treePanelVisibleBtn"
                                     style="width: 100%;height: 100%;box-sizing: border-box;padding-left: 10px">
                                    已选中{{groupNumber}}个
                                </div>
                            </CSSelect>
                            <TreePanel v-show="treePanelVisible">
                                <CSTree
                                        :tree="groupList"
                                        @change="changeStaff"
                                ></CSTree>
                            </TreePanel>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
    import {
        waterReadingUrl,
        hydropowerContractInformationUrl,
        selectWaterTonUrl,
        queryMeterGroupUrl,
        addMeterGroupUrl,
        deleteMeterGroupUrl,
        editMeterGroupUrl,
        editMeterCommitUrl,
        canPayUrl,
        editMeterBoxNoUrl,
        queryWaterBalanceUrl,
        editWaterBalanceUrl,
        editMeterLocationUrl, hydropowerGetTenantUrl,
    } from "@/requestUrl";
    import CSTabBar from "@/components/common/CSTabBar";
    import Pagination from "@/components/Pagination.vue";
    import CSTable from "@/components/common/CSTable";
    import CSSelect from "@/components/common/CSSelect";
    import CSDialog from "@/components/common/CSDialog";
    import ChooseLocation from "@/components/ChooseLocation";
    import CSTree from "@/components/common/CSTree";
    import TreePanel from "@/components/common/TreePanel";

    export default {
        data() {
            return {
                remnantSum: 0,
                balanced: 0,
                balanceSum: 0,
                openType: 1,
                meterId: null,
                checkedDepartment: [],//选中的数组
                groupNumber: 0,//选中的表组数量
                treePanelVisible: false,
                tableGroupDiog: false,
                //水表列表
                waterReadingList: {},
                // 电表列表
                electricReadingList: [],
                //input输入框提示符
                inputName: "搜索水表名称/编号/关联房间/当前业主/租客/备注",
                //表单输入条件
                inputCondition: "",
                //状态码
                checkedTaBar: 1,
                tabBar: {
                    1: "水表",
                    2: "电表",
                    // 3: "表组管理"
                },
                pageSize: 10, //页面大小`
                pageNo: 1, //页码
                pageSizegeNo: 1,
                hydropowerInfoList: [], //合同信息
                total: "",
                addWaterGroupFlag: false,
                addWaterGroupInfo: {
                    regionCode: "",
                    name: "",
                    commit: ""
                },
                MeterGroupList: [],
                groupListMony: [
                    {
                        id: 0,
                        name: '剩余预存金额不限'
                    }, {
                        id: 1,
                        name: '小于50'
                    }, {
                        id: 2,
                        name: '小于150'
                    }, {
                        id: 3,
                        name: '小于350'
                    }, {
                        id: 4,
                        name: '小于700'
                    }, {
                        id: 5,
                        name: '大于等于700'
                    }
                ],
                editWaterGroupFlag: false,
                editWaterGroupInfo: {
                    id: "",
                    name: "",
                    commit: ""
                },
                editCommitFlag: false,
                editCommitInfo: {
                    id: "",
                    type: "",
                    commit: ""
                },
                editMeterBoxNoFlag: false,
                editMeterBoxNoInfo: {
                    id: "",
                    type: "",
                    meterBoxNo: ""
                },
                editMeterLocationFlag: false,
                editMeterLocationInfo: {
                    id: "",
                    type: "",
                    locationId: "",
                    locationName: ""
                },
                groupList: [],
                checkedGroupId: "",
                editResidueBalanceFlag: false,
                queryBalanceParams: {
                    startTime: dayjs().startOf('month').format('YYYY-MM-DD'),
                    meterType: "",
                    meterId: ""
                },
                //使用的金额
                balance: null
            };
        },
        activated() {
            window.addEventListener("keydown", this.queryEnd);
        },
        deactivated() {
            window.removeEventListener("keydown", this.queryEnd);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.queryEnd);
        },
        components: {
            CSSelect,
            CSTable,
            CSTabBar,
            Pagination,
            CSDialog,
            ChooseLocation,
            CSTree,
            TreePanel
        },
        created() {
            this.queryGroupList();
            this.query();
        },
        mounted() {
            this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
                this.query(pageNo);
            });
        },
        methods: {
            treePanelVisibleD(event) {
                console.log('关闭弹框');
                this.treePanelVisible = false;
            },
            treePanelVisibleBtn() {
                event.cancelBubble = true;
                this.treePanelVisible = true;
            },
            //打开关联表组
            openTableGroup(it, event, type) {
                this.openType = type;

                this.meterId = it.meterId;
                this.groupList = this.groupList.map(item => {
                    let checked = it.groupNames && it.groupNames.includes(item.name);
                    return {
                        ...item,
                        checked
                    }
                })
                this.checkedDepartment = this.groupList.filter((item) => {
                    return item.checked;
                });
                this.groupNumber = this.checkedDepartment.length;
                this.tableGroupDiog = true;
            },
            //保存表组
            editTableGroup() {
                let groupIds = this.checkedDepartment.map(item => {
                    return item.id
                })
                this.$fly.post(canPayUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域编码
                    "type": this.openType,
                    groupIds,  //表组id
                    "meterId": this.meterId
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    this.$vc.toast('关联成功');
                    this.query();
                    this.tableGroupDiog = false;

                })

            },
            //回车查询
            queryEnd(e) {
                if (e.keyCode == 13) {
                    this.query();
                }
            },
            //状态选择按钮
            changeTabBar(index) {
                if (this.checkedTaBar == +index) {
                    return;
                }
                this.inputCondition = "";
                this.checkedGroupId = "";
                this.checkedTaBar = +index;
                this.inputName =
                    this.checkedTaBar == 1
                        ? "搜索水表编号/表箱号/位置/关联房间/当前业主/租客/备注"
                        : this.checkedTaBar == 2
                        ? "搜索电表编号/表箱号/位置/关联房间/当前业主/租客/备注"
                        : "搜索表组名称";
                this.query();
            },
            //选中状态发生变化
            changeStaff(obj) {
                const item = obj.source[obj.key];
                //const checked = item.checked;
                item.checked = !item.checked;

                this.checkedDepartment = this.groupList.filter((item) => {
                    return item.checked;
                });
                this.groupNumber = this.checkedDepartment.length;
                console.log(this.checkedDepartment);
            },
            //查询
            query(pageNo = 1,pageSize = 10) {
                if (this.checkedTaBar == 1) {
                    console.log('查询水表');

                    this.$fly
                        .post(waterReadingUrl, {
                            search: this.inputCondition, //搜索框内容
                            pageSize: pageSize, //页面大小
                            pageNo: pageNo, //页码
                            regionCode: this.$vc.getCurrentRegion().code, //区域编码
                            groupId: this.checkedGroupId,
                            balance: this.balanced
                        })
                        .then((res) => {
                            if (res.code != 0) {
                                return;
                            }
                            if (pageNo == 1) {
                                this.$vc.emit(this.$route.path, "pagination", "init", {
                                    total: res.data.total || 0,
                                    currentPage: this.pageSizegeNo,
                                    pageSize: this.pageSize,
                                });
                            }
                            this.total = res.data.total;

                            this.balanceSum = res.data.balanceSum;
                            this.waterReadingList = res.data.datas;
                        });
                } else if (this.checkedTaBar == 2) {
                    console.log('查询电表');

                    this.$fly.post(selectWaterTonUrl, {
                        search: this.inputCondition, //搜索框内容
                        pageSize: this.pageSize, //页面大小
                        pageNo: pageNo, //页码
                        balance: this.balanced,
                        regionCode: this.$vc.getCurrentRegion().code, //区域编码
                        groupId: this.checkedGroupId
                    })
                        .then(res => {
                            if (res.code != 0) {
                                return;
                            }
                            if (pageNo == 1) {
                                this.$vc.emit(this.$route.path, "pagination", "init", {
                                    total: res.data.total || 0,
                                    currentPage: this.pageSizegeNo,
                                    pageSize: this.pageSize,
                                });
                            }
                            this.total = res.data.total;
                            this.remnantSum = res.data.remnantSum;
                            this.electricReadingList = res.data.datas;
                        })
                } else {
                    this.$fly.post(queryMeterGroupUrl, {
                        search: this.inputCondition, //搜索框内容
                        pageSize: this.pageSize, //页面大小
                        pageNo: pageNo, //页码
                        regionCode: this.$vc.getCurrentRegion().code, //区域编码
                    })
                        .then(res => {
                            if (res.code != 0) {
                                return;
                            }
                            if (pageNo == 1) {
                                this.$vc.emit(this.$route.path, "pagination", "init", {
                                    total: res.data.total || res.data.datas.length,
                                    currentPage: this.pageSizegeNo,
                                    pageSize: this.pageSize,
                                });
                            }
                            this.MeterGroupList = res.data.datas
                        })
                }
            },
            //获取租客信息
            async getInformation(item) {
                this.hydropowerInfoList = await this.$fly
                    .post(hydropowerContractInformationUrl, {
                        cid: item.cid,
                        lesseeId: item.lesseeId
                    })
                    .then((res) => {
                        return res?.data;
                    });
                this.$CSDialog.alert({
                    width: "700px",
                    title: "当前业主/租客",
                    messageHtml: `
                    <div class="comment-panel" style="--item-label: 160px;">
                        <div class="item right">
                            <div class="item-label">企业/个人名称</div>
                            <div>${this.hydropowerInfoList.companyName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">合同时间</div>
                            <div>${
                        this.hydropowerInfoList.startDate ? this.hydropowerInfoList.startDate : "-"}
                              至
                              ${this.hydropowerInfoList.endDate ? this.hydropowerInfoList.endDate : "-"}
                            </div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人</div>
                            <div>${this.hydropowerInfoList.deputyName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人手机号</div>
                            <div>${this.hydropowerInfoList.deputyPhone}</div>
                        </div>
                    </div>
                `,
                });
            },
            //添加表组
            enableAddWaterGroup() {
                this.addWaterGroupInfo.commit = "";
                this.addWaterGroupInfo.name = "";
                this.addWaterGroupFlag = true;
            },
            addWaterGroup() {
                this.$fly.post(addMeterGroupUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域编码
                    name: this.addWaterGroupInfo.name,
                    commit: this.addWaterGroupInfo.commit,
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.$vc.toast("保存成功");
                        this.addWaterGroupFlag = false;
                        this.query();
                    })
            },
            //添加表组
            enableEditWaterGroup(item) {
                this.editWaterGroupFlag = true;
                this.editWaterGroupInfo.id = item.id
                this.editWaterGroupInfo.name = item.name
                this.editWaterGroupInfo.commit = item.commit
            },
            editWaterGroup() {
                this.$fly.post(editMeterGroupUrl, {
                    id: this.editWaterGroupInfo.id,
                    name: this.editWaterGroupInfo.name,
                    commit: this.editWaterGroupInfo.commit,
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.$vc.toast("修改成功");
                        this.editWaterGroupFlag = false;
                        this.query();
                    })
            },
            deleteMeterGroup: function deleteMeterGroup(id) {
                var _this9 = this;
                this.$CSDialog.confirm({
                    title: '提示',
                    message: '确定删除表组吗？',
                    onConfirm: function onConfirm() {
                        _this9.$fly.get(deleteMeterGroupUrl, {
                            id: id
                        }).then(function (res) {
                            if (res.code != 0) {
                                return;
                            }
                            _this9.$vc.toast('删除成功');
                            _this9.query();
                            _this9.$CSDialog.instance.closeDialog();
                        });
                    }
                });
            },
            //修改备注接口
            enableEditMeterBoxNo(item, type) {
                this.editMeterBoxNoInfo.id = item.meterId;
                this.editMeterBoxNoInfo.type = type;
                this.editMeterBoxNoInfo.meterBoxNo = item.meterBoxNo;
                this.editMeterBoxNoFlag = true;

            },
            editMeterBoxNo() {
                this.$fly.post(editMeterBoxNoUrl, {
                    id: this.editMeterBoxNoInfo.id,
                    type: this.editMeterBoxNoInfo.type,
                    meterBoxNo: this.editMeterBoxNoInfo.meterBoxNo,
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.editMeterBoxNoFlag = false;
                        this.$vc.toast("保存成功");
                        this.query();
                    })
            },
            enableEditMeterCommit(item, type) {
                this.editCommitInfo.id = item.meterId;
                this.editCommitInfo.type = type;
                this.editCommitInfo.commit = item.commit;
                this.editCommitFlag = true;

            },
            editMeterCommit() {
                this.$fly.post(editMeterCommitUrl, {
                    id: this.editCommitInfo.id,
                    type: this.editCommitInfo.type,
                    commit: this.editCommitInfo.commit,
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.editCommitFlag = false;
                        this.$vc.toast("保存成功");
                        this.query();
                    })
            },
            enableEditMeterLocation(item, type) {
                this.editMeterLocationInfo.id = item.meterId;
                this.editMeterLocationInfo.type = type;
                this.editMeterLocationInfo.locationId = item.locationId;
                this.editMeterLocationInfo.locationName = item.locationName;
                this.editMeterLocationFlag = true;
            },
            editMeterLocation() {
                this.$fly.post(editMeterLocationUrl, {
                    id: this.editMeterLocationInfo.id,
                    type: this.editMeterLocationInfo.type,
                    locationId: this.editMeterLocationInfo.locationId,
                    locationName: this.editMeterLocationInfo.locationName
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.editMeterLocationFlag = false;
                        this.$vc.toast("保存成功");
                        this.query();
                    })
            },
            changeLocation(location) {
                this.editMeterLocationInfo.locationId = location.id;
                let buildingName = `${location.buildingName}`
                let floor = `${location.floor}`
                let locationInfo = '';
                if (!(buildingName === '') && !(buildingName === 'undefined' && !(buildingName === undefined))) {
                    locationInfo += buildingName;
                }
                if (!(floor === '') && !(floor === undefined) && !(floor === 'undefined')) {
                    locationInfo += floor + '层';
                }
                locationInfo += `${location.specificLocation}`;
                this.editMeterLocationInfo.locationName = locationInfo;
                console.log(this.editMeterLocationInfo.locationName);
            },
            lookComment: function lookComment(comment) {
                this.$CSDialog.alert({
                    title: '查看备注',
                    message: comment
                });
            },
            seeWaterBtn(id, type) {
                let params = {
                    groupId: id,
                    type: type
                };
                //带过去的参数
                this.$router.push({
                    name: "waterGroupInfo",
                    query: params,
                });
            },
            seeElectricityBtn(id, type) {
                let params = {
                    groupId: id,
                    type: type
                };
                //带过去的参数
                this.$router.push({
                    name: "electricityGroupInfo",
                    query: params,
                });
            },
            queryGroupList() {
                this.$fly.post(queryMeterGroupUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.groupList = res.data.datas;
                    })
            },
            enableResidueBalance(item, type) {
                this.balance = null
                this.queryBalanceParams.startTime = dayjs().startOf('month').format('YYYY-MM-DD')
                this.queryBalanceParams.meterType = type;
                this.queryBalanceParams.meterId = item.meterId
                this.editResidueBalanceFlag = true;
            },
            //计算使用金额
            queryWaterBalance() {
                this.$fly.post(queryWaterBalanceUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    meterType: this.queryBalanceParams.meterType,
                    meterId: this.queryBalanceParams.meterId,
                    startTime: this.queryBalanceParams.startTime
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.balance = res.data;
                    })
            },
            editWaterBalance() {
                this.$fly.post(editWaterBalanceUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    meterType: this.queryBalanceParams.meterType,
                    meterId: this.queryBalanceParams.meterId,
                    startTime: this.queryBalanceParams.startTime,
                    balance: this.balance
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.editResidueBalanceFlag = false
                        this.$vc.toast("更新成功，请查看当前剩余预存金额");
                        this.query();
                    })
            },
            clearBalance() {
                this.balance = null
                this.editResidueBalanceFlag = false;
            }
        },
    };
</script>

<style lang="stylus">
    input[type='checkbox']
        width 20px !important
        height 0 !important
        vertical-align top !important
        margin-top 12px

    .reading-container
        .bioz
            .el-icon-caret-bottom
                padding-top 5px

        .preview-tenant
            color #000
            padding 30px
            font-size 24px
            overflow-y auto

            .field
                &:not(:last-of-type)
                    margin-bottom 20px

                &-label
                    width 60px
                    display inline-block
                    margin-right 40px
                    text-align right
                    vertical-align top

                &-content
                    display inline-block
                    max-width calc(100% - 240px)

        .btn-active {
            color: #1dafff;
            text-decoration: underline;
            cursor: pointer;
        }

        .footable.table.table-stripped.toggle-arrow-tiny span {
            line-height: 20px;
            height: 20px;
        }

        .tenant-detail-dialog
            .el-dialog__header
                display none

            .preview-tenant
                color #000
                padding 30px
                font-size 24px
                overflow-y auto

                .field
                    &-label
                        width 170px
                        display inline-block
                        margin-right 40px
                        text-align right
                        vertical-align top

                    &-content
                        display inline-block
                        max-width calc(100% - 240px)

            .el-divider__text.is-left
                font-size 24px

        .dialog-form
            padding 27px 30px
            font-size 24px
            color #000

            &-item
                &:not(:last-of-type)
                    margin-bottom 23px

                &-label
                    width 156px
                    height 33px
                    text-align right
                    margin-right 40px
                    display inline-block
                    vertical-align middle
                    line-height 33px

                & > div
                    display inline-block
                    vertical-align middle

                    input
                        &::placeholder
                            color #999
                        width 140px
                        height 40px
                        border-radius 4px
                        padding 0 5px
                        border 1px solid #979797
                        vertical-align middle
                        padding-left 10px

    .wData i {
        padding-top 0px !important
    }
</style>
